@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Amiri&family=Open+Sans&family=Poiret+One&family=Reem+Kufi&family=Roboto&display=swap);
/* Global Styles */
:root {
  --primary-color: #0085b8;
  --dark-color: #09496e;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Amiri", serif;
  direction: rtl;
  font-size: 1rem;
  line-height: 1.6;
  background-color: rgb(238, 235, 235);
  color: #333;
}

a {
  color: #0085b8;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

.container-fluid {
  margin: auto;
  overflow: hidden;
  padding: 1rem 1.2rem;
}

.card-content {
  line-height: 3rem;
}

.collection-item {
  font-size: 1.1rem;
  line-height: 3rem;
}

.collection-item a {
  font-size: 1.7rem;
}

/* Text Styles*/

.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: #0085b8;
  color: var(--primary-color);
}

.text-dark {
  color: #09496e;
  color: var(--dark-color);
}

.text-success {
  color: #28a745;
  color: var(--success-color);
}

.text-danger {
  color: #dc3545;
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.3rem;
}

/* Posts Page */

.post-form .post-form-header {
  background: #0085b8;
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr !important;
  grid-gap: 2rem;
  align-items: center;
}

.post>div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.display {
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
}

.hide {
  display: none
}

.post-image {
  cursor: pointer;
}

.display .content-image {
  position: absolute;
  top: 4%;
  left: 5%;
  width: 90%;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-right: 10%;
}

.display img {
  width: 85%;
  box-shadow: #fff 2px 2px 4px;
}

.post .comment-count {
  background: #f4f4f4;
  background: var(--light-color);
  color: #0085b8;
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Center All */

.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */

.form-card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
  max-width: 60%;
}

/* Cards */

.card {
  padding: 0.8rem;
  line-height: 2rem;
  margin: 0.7rem 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  margin: 0.5rem 0 1rem;
  border-radius: 8px;
  background-color: #fff;
}

/* List */

.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */

.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */

.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mx {
  margin: 0 0.7rem;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 2rem;
}

.mx-3 {
  margin: 0 3rem;
}

/* Grid */

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.btn {
  display: inline-block;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.max-content {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.left-align {
  text-align: left;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.btn-primary, .bg-primary, .badge-primary, .alert-primary {
  background: #0085b8;
  background: var(--primary-color);
  color: #fff;
}

.btn-light, .bg-light, .badge-light, .alert-light {
  background: #f4f4f4;
  background: var(--light-color);
  color: #333;
}

.btn-dark, .bg-dark, .badge-dark, .alert-dark {
  background: #09496e;
  background: var(--dark-color);
  color: #fff;
}

.btn-danger, .bg-danger, .badge-danger, .alert-danger {
  background: #dc3545;
  background: var(--danger-color);
  color: #fff;
}

.btn-success, .bg-success, .badge-success, .alert-success {
  background: #28a745;
  background: var(--success-color);
  color: #fff;
}

.btn-white, .bg-white, .badge-white, .alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light, .badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */

input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type="text"], input[type="email"], input[type="password"], input[type="date"], select, textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type="submit"], button {
  font: inherit;
}

table th, table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid;
}

/* table th {
  background: var(--light-color);
} */

/* Navbar */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 1rem;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: #f4f4f4;
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.footer {
  font-size: 1.3rem;
  padding: 0.8rem;
  margin-top: 0.4rem;
}

.brand-logo {
  font-size: 1.4rem;
}

.card-content h1 {
  font-size: 2rem;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

@media (max-width: 576px) {
  .grid-2 h2 {
    font-size: 1.2em;
  }
  .card-stacked h1 {
    font-size: 1.2em;
  }
  .card img {
    height: 250px;
  }
}

/* Mobile Styles */

@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }
  .grid-2, .grid-3, .grid-4 {
    grid-template-columns: 1fr;
  }
  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }
  .large {
    font-size: 2rem;
  }
  .lead {
    font-size: 1rem;
  }
  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }
  .navbar ul {
    text-align: center;
    justify-content: center;
  }
  .post {
    grid-template-columns: 1fr;
  }
  .post a, .post button {
    padding: 0.3rem 0.4rem;
  }
}
.group{position:relative;margin:45px 0}.group .form-input{background:none;background-color:white;color:grey;font-size:18px;padding:10px 10px 10px 5px;display:block;width:100%;border:none;border-radius:0;border-bottom:1px solid grey;margin:15px 0}.group .form-input:focus{outline:none;border-bottom:1px solid #5587b6}.group .form-input:focus ~ .form-input-label{top:-14px;font-size:12px;color:#5587b6}.group input[type="password"]{letter-spacing:0.3em}.group .form-input-label{color:grey;font-size:16px;font-weight:normal;position:absolute;pointer-events:none;right:5px;top:10px;transition:300ms ease all}.group .form-input-label.shrink{top:-14px;font-size:12px;color:#5587b6}

.modal-open {
  overflow: hidden;
}
.btn-outline {
  text-align: center;
  border: dotted 1px #274850;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: 80%;
  margin: auto;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.dropdown {
  display: inline;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  color: var(--primary-color);
  text-align: right;
  font-size: 1.1rem;
  white-space: nowrap;
  background-color: rgb(238, 236, 236);
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--primary-color);
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-menu.show {
  display: block;
}

